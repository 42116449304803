Reset Password Page CSS









/*body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }
  
  .reset-password-container {
    display: flex;
    height: 100vh;
  }
  
  .reset-password-left {
    flex: 1;
    background-color: white;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #3a3a3a;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
  }
  
  form {
    width: 100%;
    max-width: 300px;
  }
  
  .password-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .validate-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .validate-button:hover {
    background-color: #0056b3;
  }
  
  .reset-password-right {
    flex: 1;
    background: linear-gradient(135deg, #0046d5, #0033a0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .background-overlay {
    text-align: center;
  }
  
  .background-overlay h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .background-overlay p {
    font-size: 18px;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
   


.success-container {
  text-align: center;
  margin-top: 20px;
  padding: 15px;
  color: #155724;
  font-size: 16px;
  font-family: Arial, sans-serif;

} 


.success-message {
  margin-top: 20px;
  font-size: 18px;
  color: #28a745;
  font-weight: bold;
  text-align: center;
}

.back-to-login-link {
  display: inline-block;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer; 
  transition: color 0.3s ease;
}
*/
/* .back-to-login-link:hover {
  color: #0056b3; 
}
   */

   body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

.reset-password-container {
    display: flex;
    height: 100vh;
}

.reset-password-left {
    flex: 1;
    background-color: white;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.logo {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #3a3a3a;
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

p {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
}

/* form {
    width: 100%;
    max-width: 300px;
} */

.password-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.validate-button {
    width: 80%;
    padding: 8px;
    font-size: 14px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}

.validate-button:hover {
    background-color: #0056b3;
}

.reset-password-right {
    flex: 1;
    position: relative;
    overflow: hidden;
    background: #0033a0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset-password-right .circle {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(circle, 
    rgba(247, 245, 245, 0.2) 0%, 
    rgba(24, 43, 150, 0.5) 30%, 
    rgba(11, 11, 125, 0.7) 60%, 
    rgb(1, 25, 73) 100%);
}

.reset-password-right .circle:nth-child(1) {
    width: 400px;
    height: 400px;
    bottom: -50px;
    left: -150px;
    z-index: 5;
}

.reset-password-right .circle:nth-child(2) {
    width: 600px;
    height: 600px;
    bottom: -150px;
    left: -200px;
    z-index: 4;
}

.reset-password-right .circle:nth-child(3) {
    width: 800px;
    height: 800px;
    bottom: -250px;
    left: -250px;
    z-index: 3;
}

.reset-password-right .circle:nth-child(4) {
    width: 1000px;
    height: 1000px;
    bottom: -350px;
    left: -300px;
    z-index: 2;
}

.reset-password-right .circle:nth-child(5) {
    width: 1200px;
    height: 1200px;
    bottom: -450px;
    left: -350px;
    z-index: 1;
}

.reset-password-right .background-overlay {
    text-align: center;
    position: relative;
    z-index: 10;
    color: white;
}

.reset-password-right h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.reset-password-right p {
    font-size: 18px;
}

.success-message {
    color: green;
    margin-top: 10px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.success-container {
    text-align: center;
    margin-top: 20px;
    padding: 15px;
    color: #155724;
    font-size: 16px;
    font-family: Arial, sans-serif;
}

.success-message {
    margin-top: 20px;
    font-size: 18px;
    color: #28a745;
    font-weight: bold;
    text-align: center;
}

.back-to-login-link {
    display: inline-block;
    justify-content: center;
    margin-top: 10px;
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
}

.back-to-login-link:hover {
    color: #0056b3;
}