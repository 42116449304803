
   .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .header-right-container {
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 30px; 
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px; 
    margin-bottom: 10px;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .notification-icon,
  .info-icon {
    font-size: 20px;
    color: #666;
    cursor: pointer;
  }
  
  .profile-icon {
    width: 40px;
    height: 40px;
    background-color: #ccc;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .divider {
    width: 100%;
    height: 1px;
    background-color: #dcdcdc;
    margin: 10px 0;
  }
  
  .header-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    width: 250px;
    background-color: white;
  }
  
  .search-icon {
    color: #666;
    margin-right: 8px;
  }
  
  .search-bar input {
    border: none;
    outline: none;
    font-size: 14px;
    width: 100%;
  }
  
  .add-user-btn {
    background-color: #1a73e8;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .add-user-btn i {
    margin-right: 5px;
  }
  
  .add-user-btn:hover {
    background-color: #155bb5;
  }

  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #FFFFFF; 
  padding: 30px;
  border-radius: 10px;
  width: 400px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  position: relative;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333; 
}

label {
  /* display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333; 
  margin-bottom: 5px; */
}

