/* ResetPassword.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }
  
  .reset-password-container {
    display: flex;
    height: 100vh;
  }
  
  .reset-password-left {
    flex: 1;
    background-color: white;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #3a3a3a;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
  }
  
  .forgot-password-form {
    width: 100%;
    max-width: 300px;
  }
  
  .email-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .validate-button {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .validate-button:hover {
    background-color: #0056b3;
  }
  
  .reset-password-right {
    flex: 1;
    background: linear-gradient(135deg, #0046d5, #0033a0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .background-overlay {
    text-align: center;
  }
  
  .background-overlay h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .background-overlay p {
    font-size: 18px;
  }
  