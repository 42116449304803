
*::before,
*::after {
    box-sizing: border-box;
}

.login-box {
    width: 100%;
    max-width: 400px;
}

.login-box h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 10px;
}

.login-box p {
    font-size: 16px;
    margin-bottom: 30px;
}

.input-box {
    margin-bottom: 20px;
    position: relative;
}

.input-box input {
    width: 100%;
    padding: 15px 15px 15px 45px;
    border-radius: 15px;
    border: 1px solid #ddd;
    font-size: 16px;
    outline: none;
    background-color: white;
    box-sizing: border-box; /* חשוב לוודא שה-padding וה-border כלולים בתוך הרוחב */
}

.input-box .icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #aaa;
}

/* עיצוב לכפתור ה-Login */
.login-btn {
    width: 100%;
    padding: 15px;
    background-color: #0052d4;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
    box-sizing: border-box; /* להבטיח אותו box-sizing כמו לשדות */
}

.login-btn:hover {
    background-color: #4364f7;
}

/* עיצוב לכפתור Google */
.google-btn {
    border-radius: 15px !important; /* כופה על הכפתור לקבל את העיגול */
    width: 100%;
    padding: 15px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
}

.google-btn:hover {
    background-color: #f1f1f1;
}


.forgot-password {
    display: block;
    text-align: right;
    font-size: 14px;
    color: #555;
    text-decoration: none;
    margin-bottom: 20px;
}
