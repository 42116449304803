/* layout.css */
.container {
    display: flex;
    width: 100%;
    height: 100vh; /* מוודא שהתוכן יתפרס על כל המסך */
    background-color: white;
    overflow: hidden;
}

.left-side {
    background: linear-gradient(to bottom right, #4480dfb3, #213bb1, #00438f);
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.right-side {
    width: 50%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
}

/* רספונסיביות למסכים קטנים */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        width: 100%;
    }

    .left-side, .right-side {
        width: 100%;
        padding: 30px;
    }
}
