body {
    /* padding: 50px; */
    font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
  }
  
  /* a {
    color: #00B7FF;
  }
   */

  .cursor-pointer {
    cursor: pointer;
  }

  :where(.css-dev-only-do-not-override-142vneq).ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: unset !important;
  }

  .ant-menu-item {
    border-radius: unset !important;
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: unset !important;
    border-right: .2em solid #1677ff;
  }

  /* Pagination styles goes here */
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    border-top: 1px solid #e0e0e0; 
    margin-top: 20px;
    background-color: #ffffff;
}

.pagination-btn {
    background-color: #f5f7fa;
    color: #333;
    border: 1px solid #ddd;
    padding: 8px 12px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn:hover {
    background-color: #e0e0e0;
}

.pagination-btn.active {
    background-color: #1a73e8;
    color: white;
    border-color: #1a73e8;
}

.pagination-btn:disabled .prev-btn, .pagination-btn .next-btn{
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.ant-modal-close .ant-modal-close-x {
  position: absolute;
  top: -25px;
  right: -25px;
}

.ant-modal .ant-modal-close {
  position: absolute;
  top: 12px;
  inset-inline-end: 12px;
  z-index: 1010;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  line-height: 1;
  text-decoration: unset;
  background: unset;
  border-radius: unset;
  width: unset;
  height: unset;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
}

.ant-modal .ant-modal-title {
  text-align: center;
  font-size: 2.2em;
  font-weight: 700;
}