.template-viewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex: 3;
    height: 98%;
  }
  
  .template-viewer {
    width: 80%;
    max-width: 1200px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid #ddd;
  }
  
  .viewer-header {
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .company-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .logo-placeholder {
    width: 100px;
    height: 100px;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  
  .company-description p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
  
  .centered-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .centered-content h3,
  .centered-content h4,
  .centered-content p {
    margin: 10px 0;
  }
  
  .divider {
    width: 100%;
    height: 2px;
    background-color: #888;
    margin: 10px 0;
  }
  
  .long-divider {
    width: 100%;
    height: 3px;
    background-color: #555;
    margin: 15px 0;
  }
  
  .viewer-content p {
    margin: 15px 0;
  }
  
  .numbered-section h5 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .numbered-section ul {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .viewer-footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .page-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  