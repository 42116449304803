.content {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  margin-bottom: 20px;
}

.user-page-layout {
  display: flex;
  min-height: 100vh;
  background-color: #f5f7fa;
  overflow: hidden;
}

.content-wrapper {
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  overflow: hidden;
}
html, body {
  height: 100%;
  overflow: hidden; 
}
/* userModal.css */
.ant-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: #333;
}



