.sow-page-layout {
    display: flex;
    min-height: 100vh;
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .sow-templates-container {
    padding: 20px;
    background-color: #f9f9f9;
    flex-grow: 1;
  }