
  .sidebar {
    height: 100vh;
    width: 220px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #e0e0e0;
    flex-shrink: 0;
  }
  
  .sidebar .logo h1 {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menu ul li {
    margin-bottom: 15px;
  }
  
  .menu ul li a {
    text-decoration: none;
    color: #333;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
  }
  
  .menu ul li a i {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .menu ul li a:hover {
    background-color: #f0f4f8;
  }
  
  .menu ul li a.active {
    background-color: #e0ecff;
    color: #1a73e8;
    font-weight: bold;
  }
  
  