

/* פריסה כוללת */
.sow-page-layout {
    display: flex;
    min-height: 100vh; /* גובה מסך מלא */
  }
  
  /* אזור התוכן */
  .main-content {
    flex-grow: 1; /* תופס את שאר השטח */
    display: flex;
    flex-direction: column; /* פריסה אנכית */
  }
  
  /* תוכן מרכזי */
  .content-container {
    display: flex; /* פריסה גמישה */
    gap: 20px; /* ריווח בין האלמנטים */
    padding: 20px; /* ריווח פנימי */
    background-color: #f9f9f9; /* רקע בהיר */
    flex-grow: 1; /* ממלא את השטח הפנוי */
  }
  