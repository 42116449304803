.drag-container {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* הכפתור בתחתית */
    height: 450px; /* גובה כולל מותאם */
    width: 95%; /* רוחב פרופורציונלי */
    margin: 0 auto; /* מרכז את הקומפוננטה */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3); /* צל רך */
    border-radius: 10px;
    border: 1px solid #ddd; /* מסגרת עדינה */
    background:  #f9f9f9 /* ללא רקע */
  }
  
  .drag-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px; /* רווח בין הפריטים */
  }
  
  .drag-item {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* תוכן מיושר שמאלה */
    padding: 5px 10px; /* ריווח פנימי קטן */
    height: calc((100% - 60px) / 10); /* גובה כל פריט כולל הכפתור */
    background-color: #ffffff; /* רקע לבן לפריט */
    border: 1px solid #ccc; /* גבול לפריט */
    border-radius: 5px; /* פינות מעוגלות */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* צל קל */
    cursor: move;
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out; /* אפקט חלק */
  }
  
  .drag-item.dragging {
    border: 2px solid #007bff; /* גבול כחול בעת גרירה */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* צל בולט */
    transform: scale(1.05); /* הגדלה קלה */
  }
  
  .import-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #28a745; /* ירוק */
    color: white;
    border: 1px solid #ccc;
    border-radius: 5px; /* פינות מעוגלות */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* צל קל */
    cursor: pointer;
    text-align: center;
    height: calc((100% - 60px) / 10); /* גובה זהה לפריטים */
    margin-top: 10px; /* רווח בין הכפתור לפריטים */
    transition: background-color 0.2s ease-in-out; /* אנימציה לצבע */
  }
  
  .import-btn:hover {
    background-color: #218838; /* ירוק כהה */
  }
  