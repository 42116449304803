/* branding.css */
.branding h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
}

.branding p {
    font-size: 18px;
}

.left-side::before,
.left-side::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.left-side::before {
    width: 380px;
    height: 380px;
    bottom: -40px;
    left: -100px;
}

.left-side::after {
    width: 380px;
    height: 380px;
    bottom: -50px;
    left: -80px;
}

/* רספונסיביות */
@media (max-width: 768px) {
    .branding h1 {
        font-size: 28px;
    }

    .branding p {
        font-size: 16px;
    }
}
