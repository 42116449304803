.customContent {
  background-color: #F4F7FE;
  padding: 16px;
  overflow: auto;
  height: calc(100vh - 103px);
}

.customSidebar {
  border-inline-end: unset;
}

.customHeaderAction {
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 30px; 
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px; 
  margin-bottom: 10px;
  gap: 1.5em;
}

.customHeaderTitle {
  margin: 0;
  margin-bottom: unset !important;
  font-size: 34px !important; 
  font-weight: bold !important; 
  color: #2B3674 !important;
}

.customHeader {
  background-color: #F4F7FE;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: unset;
  height: 103px;
  border-bottom: 1px solid #E1E3E8;
}
