.input-box {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input-box input {
    width: 100%;
    padding-right: 40px; /* מרחב לאייקון */
  }
  
  .toggle-password {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  